import React from 'react';
import { Container } from 'react-bootstrap'; 
export interface BendingSpoonsSection1Props {  }
export function BendingSpoonsSection1(Props: BendingSpoonsSection1Props) { 
    return (
        <section>
            <Container style={{ textAlign: "center" }}>
                <h3 style={{ marginBottom: 30 }}>ENJOY YOUR BALI RETREAT WITH BALI HOLIDAYS AND MORE</h3>
                <p>We at Bali Holidays and More could not be more excited to be a part of the Bending Spoons Bali Retreat in February.
                        You will have a wonderful time on the “Island of the Gods”, and we are here to guarantee just that!</p>
                <p>We have three sites dedicated to Bali travel:
                        <a href="https://balihotelsandmore.com" target="_blank" rel="noopener noreferrer"> Bali Hotels and More</a>,
                        <a href="https://balivillasandmore.com" target="_blank" rel="noopener noreferrer"> Bali Villas and More</a>, and
                        <a href="https://balitoursandmore.com" target="_blank" rel="noopener noreferrer"> Bali Tours and More</a>.</p>
                <p>You can ask us anything about Bali, we are the experts!</p>

            </Container>
        </section>

    )

}