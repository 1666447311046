import React, { useState } from 'react';
import { Container,   Row, Col, Button, Modal } from 'react-bootstrap';
export interface BendingSpoonsSection6Props { }
export function BendingSpoonsSection6(Props: BendingSpoonsSection6Props) {
    const [show, setShow] = useState(false)
    return (
        <section style={{ background: `url("/assets/images/bg-content.jpg")`, padding: '50px 0 60px 0' }}>
            <Container>
                <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={6}>
                        <h2 style={{ color: "#fff" }}>What to Bring</h2>
                        <p style={{ color: "#fff" }}>Emirates Air is generous with their allowance, with a maximum of 35kg for checked baggage. So you’ll have plenty of space for your beach wear and Bali souvenirs!</p>

                        <Button onClick={() => setShow(true)}>Click for details</Button>
                    </Col>
                </Row>
                <Modal size="lg" show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>What to Bring</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>A Must</h3>
                        <ul>
                            <li> Passport. </li>
                            <li>International debit or credit cards. </li>
                            <li>Clothing for temperatures very hot (more than 30º).</li>
                            <li>Swimsuits and flip-flops (consider brining a sarong). </li>
                            <li>Any medicines, healthcare products, or special supplements that you need </li>
                            <li>Clothes to visit temples </li>
                            <li>Mosquito spray.</li>
                            <li>Sunscreen.</li>
                            <li>White clothes for the White themed party for the last night :) </li>
                            <li>Splitwise installed on your device: make sure you joined Bending Spoons group.</li>
                        </ul>
                        <h3>Great to have</h3>
                        <ul>
                            <li> Headphones, sleep mask, cushion, and any other item that may ease the long flight. </li>
                            <li>Ear plugs (you will be sharing a room with another Spooner...you never know who) </li>
                            <li>Trekking shoes</li>
                            <li>Sunglasses.</li>
                            <li>A backpack (for boat trips and excursions).</li>
                            <li>Basic stuff in carry on baggage ( in the case your luggage is delayed).</li>
                            <li>Hat/cap. To get the sun out of you head when walking a lot.</li>
                            <li>Comfortable clothes to wear when hiking.  (e.g., T-shirt, shorts, sweatpants, not-too-fancy sweater. </li>
                        </ul>
                        <h3>Nice to have</h3>
                        <ul>
                            <li>Gym clothes - At the resort there is a gym and yoga facilities</li>
                            <li>Sneakers or comfy shoes.</li>
                            <li>Any electronic devices that you like using (e.g., iPad, Power bank )</li>
                            <li>Hand sanitizer.</li>
                            <li>Waterproof jacket (It is rainy season, so you never know)</li>
                        </ul>
                    </Modal.Body>
                </Modal>
            </Container>
        </section>

    )

}