import React from 'react';
import { Container, Row, Col , Carousel } from 'react-bootstrap';

const imageType = [
    { type: 'General', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Beach-Club-2.jpg' },
    { type: 'Rooms', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Beachfront-suite.jpg' },
    { type: 'Cafe/Restaurant', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/Beach-Club1.jpg' },
    { type: 'Fitness', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0980.jpg' },
    { type: 'Pool', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Pool/IMG_4361.jpg' },
    { type: 'Spa', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/IMG_1083.jpg' },
  //   { type: 'Villa', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_24.jpg' },
    { type: 'Shop', imageUrl: 'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Other/IMG_5377.jpg' },
]

const hotelImages = {
    "General": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Beach-Club-2.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/2015-04-29-10.17.42-1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/BLoweWhite_Komune_82114_1-copy-3.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Beach-Club-Bali-by-day.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Beach-Club-Entry1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/IMG_0148.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/IMG_1216.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Keramas-Surf-1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Photo-12-12-2014-14-28-06.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Photo-12-12-2014-21-20-44.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Photo-13-12-2014-08-39-49.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Reception1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/General/Sunrise-shot.jpg'
    ],
    "Rooms": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Beachfront-suite.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Beachfrontsuite1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Resort-Room.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Resort-room-2.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Rooms/Suite-2-TV-Down.jpg',
    ],
    "Fitness": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0023.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0413.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0505.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0537.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0785.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0844.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_0980.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_4186.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/IMG_4382.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Sport%20Center/MB3A8917.jpg',
    ],
    "Pool": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Pool/IMG_4361.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Pool/Photo-10-12-2014-23-34-31.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Pool/Pool1.jpg',
    ],
    "Cafe/Restaurant": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/Beach-Club1.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/IMG_0664.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/IMG_0699.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/IMG_4193.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/MB3A9243.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/Photo-10-12-2014-21-10-04.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/Photo-13-12-2014-21-21-08.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Cafe/IMG_1234.jpg',
    ],
    "Spa": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/IMG_1083.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/IMG_5477.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/IMG_5478.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/IMG_5480.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/spa/MB3A9057.jpg',
    ],
    "Villa": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_24.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_01.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_03.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_04.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_05.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_13.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_16.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_17.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_18.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_19.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_20.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_22.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_23.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_29.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_30.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/villa/YM_komunevilla_31.jpg',
    ],
    "Shop": [
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Other/IMG_5377.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Other/IMG_1204.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Other/IMG_1214.jpg',
        'https://storage.googleapis.com/bali-hotel-images/Komune%20Resort%20and%20Beach%20Club%20Bali/Other/IMG_5383.jpg',
    ]
}

export interface BendingSpoonsSection2Props { }
export interface BendingSpoonsSection2State {
    slider: any[]
}
export class BendingSpoonsSection2 extends React.Component<BendingSpoonsSection2Props, BendingSpoonsSection2State>  {

    constructor(props: BendingSpoonsSection2Props) {
        super(props);
        let slider: any[] = [];
        for (let a of hotelImages["General"]) {
            slider.push(
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={a}
                        alt="Komune Resort & Beach Club Bali"
                    />
                </Carousel.Item>
            )
        }
        this.state = {
            slider: slider
        }
    }

    render() {
        let catImages: any[] = [];
        for (let a of imageType) {
            catImages.push(
                <div
                    style={{
                        background: `linear-gradient(
                            rgba(0, 0, 0, 0.7), 
                            rgba(0, 0, 0, 0.7)
                          ), url("${a.imageUrl}")`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        cursor: "pointer",
                        margin: 2
                    }}
                    onClick={
                        () => {
                            let slider: any[] = [];
                            let images: any[] = [];
                            switch (a.type) {
                                case "Rooms": images = hotelImages["Rooms"]; break;         
                                case "Cafe/Restaurant": images = hotelImages["Cafe/Restaurant"]; break;
                                case "Shop": images = hotelImages["Shop"]; break;
                                case "Pool": images = hotelImages["Pool"]; break;
                                case "Spa": images = hotelImages["Spa"]; break;
                                case "Fitness": images = hotelImages["Fitness"]; break;
                                
                                // case "Villa": images = hotelImages["Villa"]; break;
                                default:  images = hotelImages["General"]; break;

                            }   
                            for (let b of images) {
                                slider.push(
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src={b}
                                            alt="Komune Resort & Beach Club Bali"
                                        />
                                    </Carousel.Item>
                                )
                            }
                            this.setState({slider})
                        }
                    }
                >
                    <span style={{ fontSize: 24, fontWeight: 500, color: '#fff', lineHeight: "50px" }}>{a.type}</span>
                </div>
            )
        }
        return (
            <section
                style={
                    {
                        background: "url('https://storage.googleapis.com/bali-hotel-images/hotels-background.jpg')",
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        margin: '50px 0',
                        padding: '50px 0'
                    }
                }
            >
                <Container style={{ textAlign: "center" }}>
                    <h2 style={{ marginBottom: 30 }}>Komune Resort & Beach Club Bali</h2>
                    <p style={{ textAlign: "center", marginBottom: 30 }}>
                         Komune Resort, on Bali’s Eastern Coast, is known for legendary surf breaks and sunrises.
                           Komune Resort has been reserved exclusively for Bending Spoons for your retreat. 
                        You'll have full access to the resort's beach club, 2 pools, restaurants, yoga shalas, 
                        spa, 24-hour fitness center and more!
                        
                        </p>
                    <Row>
                        <Col md={9}>
                            <Carousel>
                                {this.state.slider}
                            </Carousel>
                        </Col>
                        <Col md={3}>
                            {catImages}
                        </Col>

                    </Row>
                </Container>
            </section>

        )

    }
}