// Loading Core Component
import React from 'react';

// Loading Style
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css";

/**
 * Bali Holidyas landing page
 */
// import { MainPages } from './sites/bali-holidays/MainPage';

/**
 * Banding Spoons Landing Page
 */
import { MainPages } from './sites/bending-spoons/MainPage';

/**
 * Main Site 
 */
// import { MainPages } from './sites/main-site/MainPage'; 
const App: React.FC = () => {
  return (
    <MainPages />
  );
}

export default App;
