import React from "react";
import {
    Navbar,
    Nav,
    Container 
} from "react-bootstrap";

export interface BendingSpoonsIndexNavBarProps { }

export interface BendingSpoonsIndexNavBarState {
    navbarColor: string;
    setNavbarColor: string;
    navbarCollapse: boolean;
    logoUrl: string;
}

export class BendingSpoonsIndexNavBar extends React.Component<BendingSpoonsIndexNavBarProps, BendingSpoonsIndexNavBarState>  {

    constructor(props: BendingSpoonsIndexNavBarProps) {
        super(props);
        document.documentElement.classList.remove("nav-open");
        this.state = {
            navbarColor: 'navbar-transparent',
            setNavbarColor: 'dark', navbarCollapse: false,
            logoUrl: '/assets/images/bali-holidays-and-more-logo-2.png'
        }
    }

    updateNavbarColor = () => { 
        if (
            document.documentElement.scrollTop > 299 ||
            document.body.scrollTop > 299
        ) {
            this.setState({ navbarColor: '', setNavbarColor: 'light', logoUrl: '/assets/images/bali-holidays-and-more-desktop-logo.png' })

        } else if (
            document.documentElement.scrollTop < 300 ||
            document.body.scrollTop < 300
        ) {
            this.setState({ navbarColor: 'navbar-transparent', setNavbarColor: 'dark', logoUrl: '/assets/images/bali-holidays-and-more-logo-2.png' })
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.updateNavbarColor);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updateNavbarColor);
    }

    render() {
        let { navbarColor, setNavbarColor } = this.state;
        let navbarStyle: any = { variant: 'light' }
        if (setNavbarColor === 'dark') {
            navbarStyle = { variant: 'dark' }
        }
        let toggleNavbarCollapse = () => {
            if (this.state.navbarCollapse) {
                this.setState({ navbarCollapse: false }, () => { document.documentElement.classList.remove("nav-open"); })
            } else {
                this.setState({ navbarCollapse: true }, () => { document.documentElement.classList.toggle("nav-open"); })
            }

        };
        return (
            <Navbar fixed="top" collapseOnSelect className={navbarColor} expand="lg" {...navbarStyle}>
                <Container>
                    <div className="navbar-translate">
                        <Navbar.Brand href="/">
                            <img   src={this.state.logoUrl} alt="bali holidyas andmore" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={toggleNavbarCollapse} >
                            <span className="navbar-toggler-bar bar1" />
                            <span className="navbar-toggler-bar bar2" />
                            <span className="navbar-toggler-bar bar3" />
                        </Navbar.Toggle>
                    </div>
                    <Navbar.Collapse className="justify-content-end" id="responsive-navbar-nav">
                        <Nav className="mr-right">
                             
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </Navbar>
        )
    }
}