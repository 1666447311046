import React from 'react';
import { Container } from 'react-bootstrap'
export interface MainIndexHeaderProps { }
export function MainIndexHeader(Props: MainIndexHeaderProps) {
  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage:
            "url(https://storage.googleapis.com/bali-hotel-images/ulun-danu-temple.jpg)",
          height: '700px', maxHeight: 700
        }}
      >
        <style>
          {
            `
            .page-header .filter::after {
              background-color: rgba(0, 0, 0, 0);
              background-image: linear-gradient(#08080852, #ffff0000);
              content: "";
              display: block;
              height: 100%;
              left: 0;
              top: 0;
              position: absolute;
              width: 100%;
              z-index: 1;
          }
          .presentation-title {
            font-size: 5em;
            font-weight: 700;
            margin: 0;
            color: #FFFFFF;
            background: #fbf8ec;
            background: linear-gradient(to bottom, #FFFFFF 35%, #FFF 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
            
            `
          }
        </style>
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              <img src="/assets/images/bending-spoons-logo.png" width="130" alt="bending-spoons" />
              <h1 className="presentation-title">BENDING SPOONS 2020</h1> 
              <div className="fog-low">
                <img alt="..." src={require("../../assets/img/fog-low.png")} />
              </div>
              <div className="fog-low right">
                <img alt="..." src={require("../../assets/img/fog-low.png")} />
              </div>
            </div> 
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("../../assets/img/clouds.png") + ")"
          }}
        />

      </div>
    </>
  ) 
}