import React, { useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';

export interface StellasChildProps { }

export function StellasChild(Props: StellasChildProps) {
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    return (
        <section style={{ background: '#e6faff', padding: 0 }}>
            <style>
                {
                    `
                        .stellas-image {
                            background: url('https://storage.googleapis.com/bali-hotel-images/stellas-child.jpg');
                            background-size: cover;
                            background-position: center;
                            height: 680px
                        }
                        `
                }
            </style>
            <Row>
                <Col md={6} style={{ padding: "20px 30px" }}>
                    <div style={{
                        maxWidth: 500, float: "right",// background: "url('/assets/images/LOE_LOGO.png')", backgroundSize: 'cover' 
                    }}>
                        <h2 style={{ textAlign: 'center', marginBottom: 20, marginTop: 0 }}>Our Charity Partner</h2>
                        <img src="https://storage.googleapis.com/bali-hotel-images/stellas-child.png" alt="stellas child" style={{ float: 'left', margin: '0 20px 20px 0' }} />
                        <p style={{ textAlign: 'justify', marginBottom: 15 }}>Stella’s Child helps underprivileged youth residing in children’s homes and orphanages open doors to experiences and opportunities in business, to develop skills, stimulate creativity, and to provide a launch pad to success to which youth may otherwise not have access. Their entrepreneurship program partners youth and mentors to develop and launch social enterprise businesses in an after school program. The program was developed as a playground for the youth to practice their entrepreneurial skills in the real world. Their Love Our Earth brand is now creating employment opportunities for graduates as they train to manage the business.</p>
                        <p style={{ textAlign: 'justify', marginBottom: 15 }}>Purchase a special in-room welcome gift of products from their brand and help support these youth!  Just indicate your interest in the planning form below and we will be in touch with regard to next steps!  Each gift costs just €35 and proceeds will be used to help launch the upcoming 2020 Entrepreneurship Program.</p>
                        <Row>
                            <Col md={8}>
                                <p style={{ textAlign: 'justify' }}>Each gift pack includes:</p>
                                <ul>
                                    <li>Custom Sarong</li>
                                    <li>Reclaimed Wood Keychain</li>
                                    <li>JIWA Card Game</li>
                                    <li>Natural Mosquito Spray</li>
                                    <li>Vanilla and Wild Honey Lip Balm</li>
                                    <li>Goodnight Sleep Tight Balm</li>
                                </ul>
                            </Col>
                            <Col md={4}>
                                <img width="100%" src="/assets/images/product-stellas-small.jpg" alt="Product gift pack" />
                                <Button style={{ width: '100%' }}
                                    onClick={() => {
                                        setShow(true)
                                    }}
                                >View Detail</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={6} className="stellas-image">
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton> 
                    <Modal.Title id="example-modal-sizes-title-sm"> Welcome gift products </Modal.Title>
                </Modal.Header>
                <img width="100%" src="https://storage.googleapis.com/bali-hotel-images/products-stellas.jpg" alt="" />
            </Modal>
        </section>

    )

} 