import React from 'react';
import { Container, Accordion, Card, Row, Col } from 'react-bootstrap';
const balis = [
    {
        header: 'DO check your passport.',
        content: 'You will need 6 months validity and 2 clean pages in your passport upon arrival in Indonesia.  If your passport expires before 31 July 2020, you will not be allowed to fly, and will be stopped at the airport. For everyone else, you will receive a visa when you arrive in Bali, and make your way through the customs line. '
    }, {
        header: 'DO dress appropriately.  ',
        content: 'Indonesia is the world’s most populated Muslim nation, but the island of Bali is Hindu. The Balinese are extremely religious and conservative, please be respectful.  When venturing off the resort or the beach, dress modestly with shirts, shorts or dresses (i.e, don’t go shirtless or in just a bikini). If you visit temples, you will be given a sarong to cover your legs.  When at Komune, swimsuits and light clothing are perfectly fine, and don’t forget your fitness and yoga wear if you plan to use the gym facilities. '
    }, {
        header: 'DO prepare for the weather. ',
        content: 'February is rainy season, which typically means showers in the morning or at night. Very rarely do we see rain all day and all night. It is also very hot and humid in February, with temperatures in the 30s. If you plan to trek Mount Batur, wear long pants and a jacket, as it is much cooler on top of the mountain at sunrise. Also, since Bali is only 8 degrees from the equator, the sun’s rays are very strong, bring your sunscreen! '
    }, {
        header: 'DO try the local cuisine.  ',
        content: 'We know Italy is famous for amazing food, but Balinese and Indonesian food has some of them most distinct flavours in the world!  Traditional meals are served family-style with satays, rices, rendang (coconut curry), whole fish, vegetables, grilled tofu, and more. Please note that soy beans and peanuts are used in many dishes, so use caution if you have allergies.  Vegetarian and gluten-free options are found in most places.  Sign up for a cooking class at Komune for a full foodie experience.'
    }, {
        header: 'DO shop local.  ',
        content: 'If you are planning on buying souvenirs, visit local markets for Balinese art, wood carvings, jewellery, sarongs and more. There will be money exchange for Indonesian Rupiah at Komune Resort. When haggling with shopkeepers, be sure to smile and be respectful. The Balinese are exceptionally friendly, especially if you attempt to use a few local words. Visit the shops on one of our cultural tours through the markets. '
    }, {
        header: 'DON’T drive a motorbike if you have never driven one before.  ',
        content: 'There are plenty of taxis, local drivers and transport apps (Go-Jek and Grab).  Be safe on the roads, helmets are mandatory, and do not drink alcohol and drive.'
    }, {
        header: 'DON’T drink the tap water.  ',
        content: 'This is a common concern for many tourists.  But don’t worry, Komune Resort supplies filtered water in all rooms, and at many stations all around the resort. The ice is OK to have in drinks too. For anyone worrying about travelling to the tropics, Bali is very safe. It is very hot and humid in February, though, be sure to stay hydrated! '
    }, {
        header: 'DON’T do drugs.  ',
        content: 'Indonesia has some of the strictest drug policies in the world, and the police are especially watchful of tourists. Do not attempt to bring any illicit substances into Bali, purchase while you are here, carry or take out of Bali.  The policy is absolutely zero-tolerance.'
    }, {
        header: 'DO go ECO!  ',
        content: 'Plastic waste is a major issue for Bali.  Wherever possible, don’t use single-use water bottles, packaging, straws, and bags.  Komune Resort is plastic-free, but when travelling off the resort, be sure to take reusable options, which are also great souvenirs! '
    }, {
        header: 'DO consult your physician if you have medical conditions ',
        content: 'There are no required vaccinations to travel to Bali, however if you have any concerns or questions, it is best to ask your doctor. Certain medications are not allowed to enter Indonesia without proper documentation, and this could include common items such as anti-depressants, pain-killers or sleeping pills. If you have conditions that require medications, it is best to bring the prescription from your physician and the medicine in its original packaging. '

    }
]

export interface BendingSpoonsSection5Props { }

export function BendingSpoonsSection5(Props: BendingSpoonsSection5Props) {

    let isiAcc1: any[] = [];
    let isiAcc2: any[] = [];
    let lop = 0
    for (let l of balis) {
        if (lop % 2 === 0) {
            isiAcc1.push(
                <Card style={{background: '#345376'}} data-radius="none">
                    <Accordion.Toggle as={Card.Header} eventKey={lop + ''}> <span style={{fontWeight: 800, color: '#fff'}}>{l.header} </span> </Accordion.Toggle>
                    <Accordion.Collapse eventKey={lop + ''}>
                        <Card.Body style={{color: '#fff'}}>{l.content}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
        } else {
            isiAcc2.push(
                <Card style={{background: '#345376'}} data-radius="none">
                    <Accordion.Toggle as={Card.Header} eventKey={lop + ''}><span style={{fontWeight: 800, color: '#fff'}}> {l.header}</span> </Accordion.Toggle>
                    <Accordion.Collapse eventKey={lop + ''}>
                        <Card.Body  style={{color: '#fff'}}>{l.content}</Card.Body>
                    </Accordion.Collapse>
                </Card>
            )
        }
        
        lop++;
    }
    return (
        <section>
            <Container style={{ textAlign: "center" , marginBottom: 30}}>
                <h2 style={{ marginBottom: 30 }}>Bali Travel  Information</h2>
                A few Do’s and Don’t to help you prepare for your Bali holiday! <br />
                Click on each for more information.
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Accordion  >
                            {isiAcc1}
                        </Accordion>
                    </Col>
                    <Col>
                        <Accordion  >
                            {isiAcc2}
                        </Accordion>
                    </Col>
                </Row>

            </Container>
        </section>

    )

}