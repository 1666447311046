import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button,   Modal } from 'react-bootstrap';
import { Timeline, TimelineEvent } from 'react-event-timeline'
import Slider from 'react-slick';

const activities: any[] = [
    {
        date: 'Thursday, 30th January	',
        icon: 'fas fa-plane-departure',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Depart Milan.  </span> Flight time 21:35.
        </p>
    },
    {
        date: 'Friday, 31st January	',
        icon: 'fas fa-door-open',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Welcome to Bali! </span>Arrive in Denpasar Airport, 22:00. 
        </p>
    },
    {
        date: 'Saturday, 1st February	',
        icon: 'fas fa-door-open',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Welcome breakfast </span> 
        </p>
    },  {
        date: 'Sunday, 2nd February		',
        icon: 'fas fa-campground',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Outing day to Virgin Beach </span> 
        </p>
    }, {
        date: 'Monday, 3rd February	',
        icon: 'fas fa-coffee',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Free Day at your leisure</span> Relax at the resort, book a spa treatment or go out and explore Bali with one of our activities below!
        </p>
    }, {
        date: 'Tuesday, 4th February ',
        icon: 'fas fa-cocktail',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Free Day at your leisure</span>  
        </p>
    }, {
        date: 'Wednesday, 5th February	',
        icon: 'fas fa-business-time',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Pool Party at Komune Resort</span> 
        </p>
    }, {
        date: 'Thursday, 6th February	',
        icon: 'fas fa-business-time',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Free Day at your leisure</span>  
        </p>
    }, {
        date: 'Friday, 7th February	',
        icon: 'fas fa-utensils',
        activity: <p>
            <span style={{ fontWeight: 800 }}>Last Day + Finale Dinner (White Party) </span><br /> 
        </p>
    }, {
        date: 'Saturday 8th February	',
        icon: 'fas fa-plane-departure',
        activity: <p>
            <span style={{ fontWeight: 800 }}>End of Retreat</span> 
        </p>
    },
]

interface Activity {
    title: string;
    content: any;
    images: string[],
    detailContent: any;
    price: number | string;
    currency: string;
}
let extraActivities: Activity[] = [
    {
        title: "Mason Adventures Day",
        content: <span />, price: 130, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Mason%20Jungle%20Buggies.jpg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/ayung-water-refting.jpg"],
        detailContent: (
            <>
                <h3>Morning White Water Rafting:</h3>
                <p>Starting your morning with get wet and wild with the pioneers of Bali’s adventure industry and enjoy a thrilling and scenic journey along the enigmatic Ayung River in Ubud. Featuring Bali’s longest white-water rafting trip and exclusively providing the only premium experience available on the island from start to finish; including the best facilities, equipment, safety, food and service.</p>

                <p style={{ fontWeight: 800 }}>Tours Inclusion:</p>
                <ul>
                    <li>Complete, Modern Safety Equipment</li>
                    <li>Gourmet Buffet Lunch</li>
                    <li>Change Rooms, Towels & Hot Showers</li>
                    <li>Electronic Security Lockers</li>
                    <li>Air-Conditioned Hotel Transfers</li>
                    <li>Emergency Accident Insurance</li>
                    <li>Emergency Air Medivac*</li>
                    <li>Free Wi-Fi</li>
                </ul>
                <p style={{ fontWeight: 800 }}>Key Information:</p>
                <ul>
                    <li>Bali’s Longest Raft Tour - 12kms</li>
                    <li>For Ages 5yr - 65yrs</li>
                    <li>Located in Ubud Area</li>
                    <li>Tour Duration approx. 3 - 4 hours</li>
                    <li>Change of Clothes Required</li>
                </ul>
                <hr />
                <h3>Afternoon Jungle Buggies:</h3>
                <p>Start your engines and get ready to embark on a journey into the jungle to experience Bali’s first and only purpose-built ATV track. This thrilling new course, spanning 5km over each lap, was crafted with the hardiest adventure seekers in mind to provide an exciting and intense driving experience like no other on the island.</p>

                <p style={{ fontWeight: 800 }}>Tours Inclusion:</p>
                <ul>
                    <li>Full Safety Equipment</li>
                    <li>3 Laps on the Track</li>
                    <li>Access to the Mason Gourmet Chocolate Factory Showroom</li>
                    <li>Delicious Lunch</li>
                    <li>Air-Conditioned Hotel Transfers</li>
                    <li>Emergency Accident Insurance</li>
                    <li>Emergency Air Medivac*</li>
                    <li>Free Wi-Fi</li>
                </ul>
                <p style={{ fontWeight: 800 }}>Key Information:</p>
                <ul>
                    <li>Lunch & Meals Available at our Onsite Restaurant</li>
                    <li>Drivers must be 18yrs+ and have a Driver's License</li>
                    <li>Tandem Passengers must be 12yrs+</li>
                    <li>Lap time depends on driver’s skill and speed</li>
                    <li>Closed Footwear Recommended</li>
                    <li>Bring Swimmers if you wish to Swim in our Pool</li>
                </ul>
                <p>
                    Please note, depending on amount of interest, we may need to split the group on the days, with half doing a reverse schedule, with rafting in the morning and jungle buggies in the afternoon.
                </p>
            </>
        )
    }, {
        title: "Snorkeling Lembongan",
        content: <span />, price: 115, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/snorkeling-di-pulau-lembongan.jpg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/snorkeling-lembongan.jpg"],
        detailContent: (
            <>
                <h3>Snorkeling in Nusa Lembongan</h3>
                <p>The beautiful island of Nusa Lembongan was once a simple, lazy haven for surfers, but it is quickly becoming a “must do” for anyone wanting to explore beyond the shores of Bali. With pristine beaches, wildly vivid snorkeling and dive spots, picturesque topography and sights, watersports, chilled out bars and restaurants, you’ll see why Bali’s closest neighbor is becoming so popular, and yet still keeping its charm. </p>
                <p>No trip to Lembongan is complete without snorkeling. The coral reefs are untouched and colourful, and the waters are calm and crystal clear. Our local guides will take you by boat out to sea, to the best locations, such as Wall Toya Pakeh (coral wall of Nusa Penida), the Giant Trevally feeding spot, and the Mangrove Spot off the coast of Lembongan, where thousands of tropical fish swim along shallow coral reefs. Take your underwater camera! </p>
                <p>Afterwards, enjoy lunch and the afternoon at Ohana’s Beach Club, one of the most popular and scenic spots in Lembongan. </p>
                <p style={{ fontWeight: 800 }}>Itinerary:</p>
                <ul>
                    <li>8.00am, pick up from Komune resort  </li>
                    <li>Check in Sanur Harbour</li>
                    <li>Fast Boat to Lembongan, departing Sanur at 09.15 am. (30 minute ride)</li>
                    <li>Arrive in Lembongan, transport to north Lembongan for snorkeling</li>
                    <li>Snorkeling trip, taken by boat around Lembongan and Penida, to 3 different spots. Snorkeling spots are Wall Toya Pakeh Penida, Giant Trevally (GT Spot) Penida, and Mangrove spot Lembongan</li>
                    <li>Beachside Lunch at Ohanas Beach Club</li>
                    <li>Fast Boat return to Bali, departing Lembongan at 16.15 (30 minutes ride)</li>
                    <li>Drop off to Komune Resort </li>
                </ul>
                <p style={{ fontWeight: 800 }}>>What Is Included In Tour Price?</p>
                <ol>
                    <li>Transport from Komune Resort to Sanur Harbor</li>
                    <li>Return Fast Boat transport to Lembongan Island</li>
                    <li>Transportation to Ohana’s & snorkeling spots, and back to Lembongan harbour</li>
                </ol>
                <p><i>**meal and drinks at Ohana’s not included. </i></p>

            </>
        )
    }, {
        title: "Bali Wake Park and Aqualand",
        content: <span />, price: 110, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Aqua-Land-1.jpeg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/wake-bali-adventure-park.jpg"],
        detailContent: (
            <>
                <h3>All day wake board cable:</h3>
                <p style={{ fontWeight: 800 }}>FROM BEGINNERS, TO PROS – ANYONE CAN DO IT!</p>
                <p>Learn to wakeboard, kneeboard, water ski and skurf on the picturesque five-hectare Cable Park lake, mounted with an overhead, state-of-the-art cable system which enables you to glide across the water surface with ease.</p>
                <p>System 2 (a two-tower station) is designed especially for beginners and children. You’ll be accompanied by expert instructors to ensure you’re comfortable and can make the most out of your wakeboarding or water-skiing experience. You’ll be taken through a step by step process, and once you’re comfortable, you can move to the full-size system.</p>
                <p>For more accomplished riders, the full-size system carries eight people at a time, and can be adjusted to suit a variety of ability levels. Obstacles and sliders are situated around the lake for experienced riders, and we provide a buggy should you wipe out to take you back to the dock.</p>
                <p style={{ fontWeight: 800 }}>GAIN NEW SKILLS, IMPRESS YOUR FRIENDS AND HAVE LOADS OF FUN, ALL UNDER PROFESSIONAL SUPERVISION ON THE TROPICAL ISLAND PARADISE OF BALI!</p>
                <h3>All day Aqualand:</h3>
                <p>Bali Wake Park Is Now Home to AQUALAND – Asia’s Largest Aqua Park!</p>
                <p>The aqua park craze has taken the world of water by storm consisting of inflatables over 8m high, you can run, jump or slide your way round various inflatables; challenge your family and friends whilst trying not to wipe out!</p>

            </>
        )
    },  {
        title: "Shopping and Markets tour of Ubud",
        content: <span />, price: 50, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/shopping-and-market-tour-ubud-bali.jpg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Ubud-Traditional-Art-Market-In-Bali.jpg"],
        detailContent: (
            <>

                <h3>Ubud Shopping and Temples tour</h3>
                <p>Explore and shop your way around Bali’s cultural capital - Ubud!</p>
                <p>Ubud Markets - Ubud market offers many attractive souvenirs made by local Balinese such as handmade bags, statues, kites, silk scarves, and many other handcrafted goods. Refine your bartering skills in this market as you can sharpen the price on almost anything you see. This is the perfect spot in Bali for great price and range of local wears and memorabilia of your Bali holiday</p>
                <p>Monkey Forest - The monkey forest is not only full of friendly and often very cheeky local but the site also offers cool walks through the lush Bali jungle and viewing of the ancient temples and guardian statues all covered in moss giving the feel of ancient Bali. You can buy some peanuts and bananas to feed the monkeys but be mindful as they do get very excited. Make sure you keep your valuables secure, close to your body and your handbag should be kept close too.</p>
                <p>Ubud Royal Palace - The palace was the official residence of the royal family of Ubud. The Ubud Royal Palace is one of the most prominent places in Ubud and was built back in the early 1800’s allowing you to see and experience Balinese architecture centuries old. The palace is surrounded by beautiful gardens and is considered one of the most adored buildings in Bali by locals.</p>
                <p>Goa Gaja Temple - The Goa Gajah Temple is a cave built from a crevasse edge and built into the rock cliff face and dates back to the 11th century. Built for the local Balinese Hindus requirement for meditation. The cave is shallow and inside are three stone idols each wrapped in red, yellow and black cloths.</p>
                <p>This is a tailored tour for Bending Spoons.  Timings can be adjusted, depending on the group.  Pickup from the Komune Resort will be around 8:30am.</p>

                <p style={{ fontWeight: 800 }}>Inclusions:</p>
                <ul>
                    <li>Transport to Ubud and attractions</li>
                    <li>admission fees for attractions</li>
                    <li>English speaking, knowledgeable guide </li>
                </ul>
                <p style={{ fontWeight: 800 }}>What do I need to bring with me?</p>
                <ul>
                    <li>Camera</li>
                    <li>Comfortable footwear</li>
                    <li>Additional local currency if you want to purchase from the local traders</li>
                </ul>
                <p style={{ fontStyle: 'italic' }}>Lunch - TBD.  Ubud is home to some of the most famous and inventive restaurants in SE Asia.  Locavore, Room4Dessert or Hujan Locale are just a few.  We can make recommendations and reservations for these, or recommend more local fare options.</p>
            </>
        )
    }, {
        title: "Bali Culture Immersion",
        content: <span />, price: 50, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/tirta%20empul.jpg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/tegalalang.jpg", "https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/ubud-monkey-forest-bali.jpg"],
        detailContent: (
            <>
                <h3>Bali Cultural Immersion Tour:</h3>
                <p>Immerse yourself in the rich culture of Bali, and don’t forget your camera!</p>
                <p>Tirta Empul - Tirta Empul is one of the largest and busiest water temples in Indonesia. The temple was founded in 926 A.D. and is dedicated to Vishnu, who is the Hindu God of water. Here you can bathe in the natural spring waters or ‘holy water’ of Bali like so many Balinese Hindu’s before you over thousands of years.</p>
                <p>Tegalalang Rice Terrace -  The most famous of all of Bali’s elevated rice fields and one you have most probably scene in many travel books.  Take in the beauty of the intricate workmanship that it has taken to shape the natural slopes involving the subak (traditional Balinese cooperative irrigation system).</p>
                <p>Monkey Forest - The monkey forest is not only full of friendly and often very cheeky local but the site also offers cool walks through the lush Bali jungle and viewing of the ancient temples and guardian statues all covered in moss giving the feel of ancient Bali. You can buy some peanuts and bananas to feed the monkeys but be mindful as they do get very excited. Make sure you keep your valuables secure, close to your body and your handbag should be kept close too.</p>
                <p>Ubud Royal Palace - The palace was the official residence of the royal family of Ubud. The Ubud Royal Palace is one of the most prominent places in Ubud and was built back in the early 1800’s allowing you to see and experience Balinese architecture centuries old. The palace is surrounded by beautiful gardens and is considered one of the most adored buildings in Bali by locals.</p>
                <p style={{ fontWeight: 800 }}>Inclusions:</p>
                <ul>
                    <li>Transport to Ubud and attractions</li>
                    <li>admission fees for attractions</li>
                    <li>English speaking, knowledgeable guide </li>
                </ul>
                <p style={{ fontWeight: 800 }}>What do I need to bring with me?</p>
                <ul>
                    <li>Camera</li>
                    <li>Comfortable footwear</li>
                    <li>Additional local currency if you want to purchase from the local traders</li>
                    <li>Change of clothes if you want to take part in the purification ceremony in the water temple.</li>
                </ul>
                <p style={{ fontStyle: 'italic' }}>Lunch - TBD.  Ubud is home to some of the most famous and inventive restaurants in SE Asia.  Locavore, Room4Dessert or Hujan Locale are just a few.  We can make recommendations and reservations for these, or recommend more local fare options.</p>

            </>
        )
    },
    {
        title: "Cooking Class ",
        content: <span />, price: 40, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Cooking%20Class%20with%20Komune%20Resort%20Chef.jpg"],
        detailContent: (
            <>
                <h3>Cooking Class with Chef Suangga</h3>
                <p>Get an in-depth taste of the local flavours, from Komune Resort’s own Head Chef Suangga.  Learn cooking techniques, and get to know the fresh herbs, spices and other ingredients that combine to make the unique flavours of Balinese cooking. Being Komune, you can also expect a bit of fusion and healthy flare! The class will be taught in the kitchen of the Health Hub, on-site at Komune. </p>
                <p style={{ fontWeight: 800 }}>Key Information:</p>
                <ul>
                    <li>Classes take approximate 3 hours (1/2 day)</li>
                    <li>Class size is up to 25 guests. </li>
                    <li>What to bring: An open mind and any questions you may have, and an appetite!</li>
                    <li>Menus will vary, so feel free to take a few classes during the week.</li>
                </ul>
            </>
        )
    },
    {
        title: "Surfing Lessons",
        content: <span />, price: 'TBD', currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Surfing%20Lessons.jpg"],
        detailContent: (
            <>
                <p>Private surf lessons are a great choice for people who want to make the most of their time in the water and progress quickly with their surfing skills. If you are a beginner or an intermediate surfer, one on one guidance from our qualified and experienced surf instructors are sure to be the answer to improving your skills on a wave.</p>
                <p>**Please note, surf breaks change with the seasons.  We cannot set an exact location for the lessons at this time, and will know as it gets closer to the retreat.  The lessons may be as easy as grabbing a board and learning from the Komune Resort instructors, or you may have to travel to a nearby locale.  </p>

                <p style={{ fontWeight: 800 }}>What to bring: </p>
                <ul>
                    <li>Change of clothes</li>
                    <li>Straps for spectacles or swimming goggles for people who wear contact lenses</li>
                    <li>Sunscreen lotion and basic toiletries</li>
                    <li>Swimming suit</li>
                    <li>Shorts for surfing</li>
                    <li>Sandals/ flip flops</li>
                </ul>
            </>
        )
    },
    {
        title: "Helicopter Tours",
        content: <span />, price: '1,325', currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Mason%20air%20Coastline%20tours.jpg"],
        detailContent: (
            <>
                <h3>Helicopter tour: Majestic Coastline Flight!</h3>
                <p>These islands off the coast of Bali offer a wealth of elegant beaches, bays and cliffs, blissfully free of crowds. For a 25 minutes will be flying thru Nusa Penida (Klingking beach) plus Dream beach and then cut back over Crystal bay of Nusa Lembongan.</p>
                <p style={{ fontWeight: 800 }}>Flight Inclusions:</p>
                <ul>
                    <li>Luxury Scenic Flight</li>
                    <li>Up to 5 Passengers*</li>
                    <li>Pick up direct on Komune Resort heliport</li>
                    <li>Mineral Water & Cold Towels</li>
                    <li>Accident Insurance</li>
                    <li>Emergency Air Medivac#</li>
                </ul>
            </>
        )

    },
    {
        title: " Scuba Diving (certified)",
        content: <span />, price: 155, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/ScubaDiving%20Padangbai.jpg"],
        detailContent: (
            <>
                <h3>Diving on Padang Bai!</h3>
                <p>Padang Bai and Blue Lagoon dive sites are situated on the east coast of Bali, a fifty-minute drive from Sanur. </p>
                <p>This area gives scuba divers great choice, from the calm waters of Blue Lagoon, which is suitable for those trying diving for the first time to Jepun, Turtleneck or Jetty. </p>
                <p>Other Dive Sites include Gili Tepekong and Gili Mimpang which require divers to be very proficient and experienced in dealing with unpredictable currents.</p>
                <p>This diving site is perfect because it’s located only 40 minutes drive’s from Komune Resort.</p>
                <p>This site is perfect for None Certified & Certified divers. </p>
                <p style={{ fontWeight: 800 }}>Inclusion:</p>
                <ul>
                    <li>Pick up/drop off service to our Sanur Dive Centre (for Sanur, Kuta, Seminyak, Nusa Dua, Tanjung Benoa and Jimbaran)</li>
                    <li>All transportation</li>
                    <li>Full equipment</li>
                    <li>Two fully supervised dives</li>
                    <li>Lunch</li>
                    <li>Bottled water</li>
                    <li>Beach towels</li>
                    <li>PADI Instructor</li>
                    <li>Dive insurance</li>
                </ul>
            </>
        )
    },
    {
        title: "Scuba Diving(non-certified)",
        content: <span />, price: 185, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/CG_A_Diving-Bora_D8X_4257.jpg"],
        detailContent: (
            <>
                <h3>Diving on Padang Bai!</h3>
                <p>Padang Bai and Blue Lagoon dive sites are situated on the east coast of Bali, a fifty-minute drive from Sanur. </p>
                <p>This area gives scuba divers great choice, from the calm waters of Blue Lagoon, which is suitable for those trying diving for the first time to Jepun, Turtleneck or Jetty. </p>
                <p>Other Dive Sites include Gili Tepekong and Gili Mimpang which require divers to be very proficient and experienced in dealing with unpredictable currents.</p>
                <p>This diving site is perfect because it’s located only 40 minutes drive’s from Komune Resort.</p>
                <p>This site is perfect for None Certified & Certified divers. </p>
                <p style={{ fontWeight: 800 }}>Inclusion:</p>
                <ul>
                    <li>Pick up/drop off service to our Sanur Dive Centre (for Sanur, Kuta, Seminyak, Nusa Dua, Tanjung Benoa and Jimbaran)</li>
                    <li>All transportation</li>
                    <li>Full equipment</li>
                    <li>Two fully supervised dives</li>
                    <li>Lunch</li>
                    <li>Bottled water</li>
                    <li>Beach towels</li>
                    <li>PADI Instructor</li>
                    <li>Dive insurance</li>
                </ul>
            </>
        )
    },
    {
        title: "Mount Batur sunrise hike",
        content: <span />, price: 60, currency: 'USD',
        images: ["https://storage.googleapis.com/mystoresandmore/Mason%20Cycling%20tour.jpg"],
        detailContent: (
            <>
                <h3>Mount Batur Sunrise & Swim!</h3>
                <p>There is a reason the sunrise trek to the summit of Mount Batur (1717 meters) is so popular. </p>
                <p>Besides experiencing the awesome beauty of an active volcano, trekking in the cool, early morning darkness obscures the difficulty of the challenging hike (torches, hiking sticks and wet weather gear supplied, if needed). </p>
                <p>The dark also helps to focus walkers to do the one to two-hour ascent making it feel less difficult. The bonus is arriving in time to witness a glorious sunrise and experience the stunning morning views of the surrounding crater and lake.</p>
                <p>And finish it off with soaking on the natural hot spring water, what an amazing experience!</p>
                <p style={{ fontWeight: 800 }}>Inclusion:</p>
                <ul>
                    <li>Hotel transfer</li>
                    <li>Entrance fee</li>
                    <li>Licensed English-speaking guide</li>
                    <li>Light breakfast</li>
                    <li>Gear / equipment</li>
                    <li>Coffee break</li>
                    <li>Hot spring entrance fee</li>
                    <li>Brunch</li>
                </ul>
            </>
        )
    },
    {
        title: "ATV's",
        content: <span />, price: 60, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Bali-island-ATV-Surya-riuver-jump.jpg"],
        detailContent: (
            <>
                <h3>ATV Adventures!</h3>
                <p>Mix fun adventure and discovery with an ATV Bali tour with Bali Surya Adventures.</p>
                <p>Based in a rural area of central Bali, you’ll experience rice paddies, plantations, forests, jungle, river crossings, traditional villages and unforgettable panoramic views. </p>
                <p>The course is challenging in places and sure to get the adrenaline pumping, but the experienced guides will always be on hand to help you out. </p>
                <p>A full safety briefing is given before your tour as well as safety equipment. Prepare yourself for a real adventure into the heart of rural Bali!</p>
                <p style={{ fontWeight: 800 }}>Inclusion: </p>
                <ul>
                    <li>International standard equipment</li>
                    <li>Guides </li>
                    <li>Insurance </li>
                    <li>Pick up and Return Transfers </li>
                    <li>Lunch</li>
                </ul>

            </>
        )
    },
    {
        title: "Cycling tour",
        content: <span />, price: 65, currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Mason-Adventures-Mountain-Cycling-Gallery-2.jpg"],
        detailContent: (
            <>
                <h3>Half Day Cycling Tour:</h3>
                <p>Get ready to embark on a journey off the beaten track, through some of Bali’s most scenic highland locales. </p>
                <p>Start your ride near the renowned Mount Batur Volcano, overlooking Kintamani Lake, and cycle down through 30kms of forest trails, ancient Hindu temples, villages, rice fields, and plantations, sampling exotic fruits at their natural source.</p>

                <p style={{ fontWeight: 800 }}>Inclusion:</p>
                <ul>
                    <li>Modern Mountain Bikes & Helmets</li>
                    <li>Lunch</li>
                    <li>Mineral Water During Trip</li>
                    <li>Refreshing Cold Towels</li>
                    <li>Air-Conditioned Hotel Transfers</li>
                    <li>Emergency Accident Insurance</li>
                    <li>Emergency Air Medivac*</li>
                    <li>Free Wi-Fi</li>
                </ul>
                <p style={{ fontWeight: 800 }}>KEY INFORMATION:</p>
                <ul>
                    <li>Tour Located: Kintamani to Tatag</li>
                    <li>Tour Duration approx. 3 – 4 hours</li>
                    <li>Closed Footwear Recommended</li>
                </ul>

            </>
        )
    },
    {
        title: "Motocross",
        content: <span />, price: 'TBD', currency: 'USD',
        images: ["https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/Bali%20Dirt%20Bike%20Kintamani%20Forest.jpg"],
        detailContent: (
            <>
                <h3>Dirt bike!</h3>
                <p style={{ fontWeight: 800 }}>Kintamani Forest:</p>
                <p>Ride through deep forest trails to wide open tracks on the Kintamani forest dirt bike tour. </p>
                <p>This tour will satisfy the adrenaline junkie in all of you!</p>
                <p>Kintamani forest is a top area for Enduro riding and a very challenging ride designed for more experienced riders!</p>
                <p></p>
                <p>Thanks to the altitude of Kintamani area (from 1000m to 2500m) the temperature stays cool and pleasant whilst the surroundings of Batur Lake offer spectacular views and you will go through various altitudes along the tracks.</p>
                <p>The trail offers a wide selection of terrain from dense forest, steep downhill jungle and sandy riverbeds.</p>
                <p></p>
                <p>This tour offers all an experienced motor bike rider could hope for. You will have the opportunity for amazing photos, and you can also hire one of our Go Pro’s to capture the best of your ride.</p>
            </>
        )
    },


]

export interface BendingSpoonsSection3Props { }

export function BendingSpoonsSection3(Props: BendingSpoonsSection3Props) {
    let isiRitrit: any[] = [];
    for (let a of activities) {
        isiRitrit.push(
            <TimelineEvent title=" "
                createdAt={a.date}
                icon={<i className={a.icon} />}
            >  {a.activity} </TimelineEvent>
        )
    }
    let showView: any = {
        "Mason Adventures Day": false,
        "Snorkeling Lembongan": false,
        'Bali Wake Park and Aqualand': false, 
        "Shopping and Markets tour of Ubud": false,
        'Bali Culture Immersion': false,
    }
    const [show, setShow] = useState(showView);
    const handleClose = () => { setShow(showView) }

    useEffect(() => {
        console.log("show", show)
    }, [show])
    let slides: any[] = [];
    for (let i of extraActivities) {
        slides.push(
            <>
                <Card>
                    <div style={{ background: `url("${i.images[0]}")`, backgroundSize: 'cover', backgroundPosition: 'center', minHeight: 230, width: '100%', borderRadius: '12px 12px 0 0' }} />
                    <Card.Body>
                        <Card.Title style={{ textAlign: 'center', marginBottom: 20 }}>{i.title}</Card.Title>
                        <Card.Text>
                            {i.content}
                        </Card.Text> 
                        <Card.Footer>
                            <div className="author">
                                <Button variant="primary" onClick={() => {
                                    console.log("lop", i.title)
                                    let s: { [x: number]: boolean } = { [i.title]: true }
                                    setShow(s);
                                }}>See More Detail</Button>
                            </div>
                            <div className='status'>
                                {i.currency} {i.price}
                            </div>

                        </Card.Footer>
                    </Card.Body>

                </Card>
                <Modal size="lg" show={show[i.title]} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{i.title} Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {i.detailContent}
                    </Modal.Body>
                    <style>
                        {
                            ` 
                        div.modal-body {
                            background: #ffffffd6;
                        }
                        div.modal-body:after {
                                content: "";
                                background: url("${i.images[0]}");
                                background-size: cover;
                                background-position: center;
                                opacity: 0.6;
                                top: 0;
                                left: 0;
                                bottom: 0;
                                right: 0;
                                position: absolute;
                                z-index: -1;   
                              
                        }
                        .modal-header {
                            background: #fff;
                        }
                        .modal-content {
                            background-color: transparent !important;
                        }
                        `
                        }
                    </style>
                </Modal>

            </>
        )
    }
    let settings: any = {
        dots: true,
        infinite: true,
        speed: 3000,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        centerPadding: 20,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
        ],
        marginTop: 30
    };
    return (
        <>
            <section style={{ marginBottom: 30 }}>
                <Container>
                    <h2 style={{ textAlign: "center" }}>Itinerary  </h2>
                    <h5 style={{ textAlign: "center" }}>A draft itinerary is below, so that you can plan your week to be as relaxing or as fun-filled as you would like!</h5>
                    <Timeline>
                        {isiRitrit}
                    </Timeline>
                </Container>
            </section>
            <section style={{ background: `url("https://storage.googleapis.com/bali-hotel-images/bending-spoons-intinaries/bali-wallpaper-18-705537.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center', }}>
                <Container>
                    <h2 style={{ textAlign: 'center', color: '#f0f0f0' }}> THINGS TO DO IN BALI! </h2>
                    <Row style={{ marginTop: 20 }} >
                        <Col md={12} style={{ marginBottom: 30 }}>
                            <p style={{ color: '#f0f0f0' }}>We have over 100 tours and activities on Bali Tours and More!  There are endless ways for you to experience everything the Island of the Gods has to offer!</p>
                        </Col>
                    </Row>
                    <Slider   {...settings}>
                        {slides}
                    </Slider>
                </Container>
            </section> 
            <style>
                {
                    `
                    .modal-title.h4 {
                        float: left;
                        margin-top: 0;
                    }
                    .card-footer .status {
                        float: right;
                        line-height: 32px;
                        font-size: 16px;
                    }
                    .card .card-body .card-footer {
                        padding: 0;
                        border-top: 1px solid #f0f0f0;
                        padding-top: 15px;
                    }
                    `
                }
            </style>
        </>

    )

}
