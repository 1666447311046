import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import { HomePage } from './pages/home/HomePage'

export interface MainPagesProps { }

export function MainPages(Props: MainPagesProps) {


  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" render={props => <HomePage {...props} />} />

        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  )


}