import React from 'react';

interface FormCheckBoxProps {
    ClickHandler: (checked: boolean) => any,
    label: string;
    value?: string;
    checked?: boolean;
}

export const FormCheckBox = (Props: FormCheckBoxProps) => (
    <div className="form-check">
        <label className="form-check-label" onClick={(event: any) => {
            event.preventDefault();
            event.stopPropagation();
            Props.ClickHandler(!Props.checked);
        }}>
            <input checked={Props.checked} type="checkbox" className="form-check-input" value={Props.value} />{Props.label} <span className="form-check-sign"></span>
        </label>
    </div>
)
