import React from 'react';
import ReactDOM from 'react-dom';  
import App from './App';
import * as serviceWorker from './serviceWorker'; 
// import * as firebase from 'firebase';

export default function runBootStrap() {
    //  bootStrapHotelApp(); 
    /*
    fetch('/__/firebase/init.json').then(response => {
        response.json().then(confFirebase => { 
            let firebaseApp: firebase.app.App = null!;
            if (!firebase.apps || firebase.apps.length === 0) {
                if (!confFirebase) {
                    alert('Firebase config not found in application. please report this problem to your admin');
                }
                firebaseApp = firebase.initializeApp(confFirebase);
            } else {
                firebaseApp = firebase.apps[0];
            }
            firebaseApp.auth().signInAnonymously()
                .then(d => {
                    ReactDOM.render(<App />, document.getElementById('root'));

                }).catch(exc => {
                    console.error('Gagal login, error: ', exc);
                });

        });

    });
    */
   ReactDOM.render(<App />, document.getElementById('root'));
}

runBootStrap();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();
