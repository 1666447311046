import React from 'react';

import { MainIndexHeader } from '../../MainIndexHeader';
import { BendingSpoonsIndexNavBar } from '../../../../components/nav-bar/BendingSpoonsIndexNavBar';
import { BendingSpoonsSection1 } from './BendingSpoonsSection1';
import { BendingSpoonsSection2 } from './BendingSpoonsSection2';
import { BendingSpoonsSection4 } from './BendingSpoonsSection4';
import { BendingSpoonsSection3 } from './BendingSpoonsSection3';
import { InThePress } from '../../../../components/base-saction/InthePress'
import { StellasChild } from '../../../../components/base-saction/StellasChild';
import { AboutBali } from '../../../../components/base-saction/AboutBali'; 
import { BendingSpoonsSection5 } from './BendingSpoonsSection5';
import { BendingSpoonsSection6 } from './BendingSpoonsSection6';
export interface HomePageProps { } 

export function HomePage(Props: HomePageProps) {
  return (
    <>
      <BendingSpoonsIndexNavBar />
      <MainIndexHeader />
      <BendingSpoonsSection1 />
      
      <BendingSpoonsSection2 />
      <BendingSpoonsSection5 />
      <BendingSpoonsSection6 />
      <BendingSpoonsSection3 />
      <StellasChild />
      <BendingSpoonsSection4 />

      <AboutBali />
      <InThePress />
    </>
  )

}
