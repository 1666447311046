import React from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import { FormCheckBox } from '../../../../components/base-component/FormCheckBox';
import axios from 'axios'

const extraTours: string[] = [
    'Mason Adventures Day – Jungle Buggies & White Water Rafting',
    'Bali Wake Park and Aqualand',
    'Snorkeling Lembongan',
    'Shopping and Markets tour of Ubud',
    'Bali Culture Immersion',
    'Cooking Class with Komune Resort Chef',
    'Surfing Lessons',
    'Helicopter Tours',
    'Scuba Diving (certified)',
    'Scuba Diving (non-certified)',
    'Mount Batur sunrise hike',
    `ATV's`,
    'Cycling tour',
    'Motocross',
    'Stella’s Child:  YES!  I am interested in purchasing a Love Our Earth welcome gift to help support the youth entrepreneurship program.'
    // 'Other – tell us if there is anything else you had in mind.',
]

interface FormData {
    name: string;
    email: string;
    birthDate: string;
    gender: string;
    phoneNumber: string;
    pasportNumber: string;
    haveToBali: string;
    dateLeavingFromItalia: string;
    dateLeavingFromBali: string;
    healthRequirements: string;
    firstChoice: string;
    secondChoice: string;
    extraActivitys: string[];
}

interface mailData {
    fromEmail: string;
    fromName: string;
    to: string;
    subject: string;
    mailContent: string;
}
export interface BendingSpoonsSection4Props { }

export interface BendingSpoonsSection4State {
    validated: boolean;
    setValidated: boolean;
    formData: FormData;
    extraActivity: { [no: number]: boolean };
    otherActivityStyle: any;
    otherActivityText: string;
    statusSubmit: boolean;
}
export class BendingSpoonsSection4 extends React.Component<BendingSpoonsSection4Props, BendingSpoonsSection4State>  {

    // lop: number = 1;
    constructor(props: BendingSpoonsSection4Props) {
        super(props);
        this.state = {
            validated: false,
            setValidated: false,
            formData: {
                name: '',
                email: '',
                birthDate: '',
                gender: '',
                phoneNumber: '',
                pasportNumber: '',
                haveToBali: '',
                dateLeavingFromItalia: '',
                dateLeavingFromBali: '',
                healthRequirements: '',
                firstChoice: '',
                secondChoice: '',
                extraActivitys: [],

            }, statusSubmit: false,
            extraActivity: {
                0: false, 1: false, 2: false, 3: false, 4: false, 5: false, 6: false,
            },
            otherActivityStyle: {
                display: 'none',
                marginLeft: 30,
                width: "calc(100% - 30px)"
            },
            otherActivityText: ''
        }
    }
    render() {

        let handleSubmit: (event: any) => any = (event: any) => {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            let err = 0;
            if (form.checkValidity() === false) {
                let formHeader: any = document.getElementById('formHeader');
                formHeader.scrollIntoView({
                    behavior: 'smooth'
                });
                this.setState({ validated: true, setValidated: true })
                err = 1
            }
            if (err === 0) {
                console.log("this.state.formData ", this.state.formData);
                let opt = "";
                let extraActivitys: string[] = this.state.formData.extraActivitys;
                for (let a of this.state.formData.extraActivitys) {
                    if (a !== "Other (tell us if you have any requests or ideas)") {
                        opt += `- ${a} <br>`
                    }
                }
                if (extraActivitys.indexOf("Other (tell us if you have any requests or ideas)") > -1) {
                    opt += `- ${this.state.otherActivityText} <br>`
                }
                let mailContent: string = ` 
                <p><span style="font-size:14px">Name</span><br> ${this.state.formData.name} </p> 
                <p><span style="font-size:14px">Email</span><br> ${this.state.formData.email} </p> 
                <p><span style="font-size:14px">Tour Selected</span> <br> ${this.state.formData.secondChoice}</p>
                <p><span style="font-size:14px">Extra activities: </span> <br> 
                ${opt}</p>
 
                `;

                /**
                <p><span style="font-size:14px">Email</span> <br>${this.state.formData.email}</p>
                <p><span style="font-size:14px">Date of Birth</span> <br>${this.state.formData.birthDate}</p>
                <p><span style="font-size:14px">Gender</span> <br>${this.state.formData.gender} </p>
                <p><span style="font-size:14px">Phone Number</span><br> ${this.state.formData.phoneNumber}</p>
                <p><span style="font-size:14px">Country of Origin</span> <br> ${this.state.formData.pasportNumber}</p>
                <p><span style="font-size:14px">Have You Been to Bali Before?</span> <br> ${this.state.formData.haveToBali}</p>
                <p><span style="font-size:14px">What day will you be leaving Italy?</span> <br> ${this.state.formData.dateLeavingFromItalia}</p>
                <p><span style="font-size:14px">What date will you be leaving Bali?</span> <br> ${this.state.formData.dateLeavingFromBali}</p>
                <p><span style="font-size:14px">Dietary / Health Requirements </span> <br> ${this.state.formData.healthRequirements}</p>
                <p><span style="font-size:14px">First Choice (Monday 3rd February)</span> <br> ${this.state.formData.firstChoice}</p>
                 */
                let mailData: mailData = {
                    fromName: this.state.formData.name,
                    fromEmail: this.state.formData.email,
                    subject: "Bending Spoons Enquiry",
                    mailContent: mailContent,
                    to: 'info@baliholidaysandmore.com'
                }

                axios.post("https://us-central1-bali-hotels-and-more-app.cloudfunctions.net/sendMail", mailData).then(
                    (d) => {
                        console.log(d)
                        this.setState({ statusSubmit: true })
                    }
                ).catch(
                    (err) => {
                        console.log(err)

                    }
                )
                console.log(mailData);

            }
        }

        let validated = this.state.validated;

        if (this.state.statusSubmit) {
            return (
                <section
                    style={{
                        background: "url('https://storage.googleapis.com/bali-hotel-images/background/testimonial-bg-dark.jpg')",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        padding: '40px 0 20px',
                        textAlign: 'left',
                        color: '#fff'
                    }
                    }
                >
                    <Container >
                        <h2 id="formHeader" style={{ marginBottom: 15, color: '#fff', textAlign: 'center' }}>
                            Thank You
                        </h2>
                        <h4 id="formHeader" style={{ marginBottom: 15, color: '#fff', textAlign: 'center' }}>
                            Thank you for submitting your information for the Bending Spoons Bali Retreat 2020.
                            A member of the Bali Holidays and More team will contact you very soon, to plan your Bali holiday.
                        </h4>
                        <h4 id="formHeader" style={{ marginBottom: 15, color: '#fff', textAlign: 'center' }}>To get in touch with us, you can email <a href="mailto:info@baliholidaysandmore.com">info@baliholidaysandmore.com</a> anytime, and our team will get back to you as soon as we can. </h4>

                    </Container>

                </section>
            )
        }

        let acts: any[] = [];
        let lop = 0;
        for (let a of extraTours) {
            acts.push(
                <FormCheckBox
                    key={"FormCheckBox" + lop}
                    checked={this.state.extraActivity[lop]}
                    ClickHandler={
                        (checked: boolean) => {
                            let extraActivity = this.state.extraActivity;
                            let extraActivitys: string[] = this.state.formData.extraActivitys!;
                            // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                            if (checked) {
                                extraActivitys.push(a);
                            } else {
                                let index = extraActivitys.indexOf(a);
                                if (index > -1) {
                                    extraActivitys.splice(index, 1);
                                }
                            }
                            // extraTours[lop] = !a
                            this.setState({ extraActivity })
                        }
                    }
                    label={a}
                />
            )
            lop++
        }
        return (
            <section
                style={{
                    background: "url('https://storage.googleapis.com/bali-hotel-images/background/testimonial-bg-dark.jpg')",
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    padding: '40px 0 20px',
                    textAlign: 'left',
                    color: '#fff'
                }
                }
            >
                <Container >
                    <h2 id="formHeader" style={{ marginBottom: 15, color: '#fff', textAlign: 'center' }}>
                        YOUR BALI RETREAT

                        </h2>
                    <p style={{ margin: 'auto', marginBottom: "20px", maxWidth: 900, color: '#fff', textAlign: 'center' }}>To help us plan your Bali retreat, let us know what you would like to do!  For tours and activities (especially if you are booking in groups), it will be easier for us if you pre-book, using the form below.  We will of course be on-site for booking, but we would like to have everyone mostly organized before you arrive in Bali.</p>
                    <p style={{ margin: 'auto', marginBottom: "20px", maxWidth: 900, color: '#fff', textAlign: 'center' }}>Let us know if you have any questions, or need more recommendations!</p>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row>
                            <Col md={3}>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="name">
                                    <Form.Label  >   Name </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="your name"
                                        value={this.state.formData.name}
                                        onChange={(evt: any) => {
                                            let formData = this.state.formData;
                                            formData.name = evt.target.value;
                                            this.setState({ formData });
                                        }}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please type your name</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="email">
                                    <Form.Label  >   Email </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="your email"
                                        value={this.state.formData.email}
                                        onChange={(evt: any) => {
                                            let formData = this.state.formData;
                                            formData.email = evt.target.value;
                                            this.setState({ formData });
                                        }}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">Please type your email</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 10 }}>
                            <Col md={6}>
                                <Form.Group controlId="ExtraActivities">
                                    <FormCheckBox
                                        key={"FormCheckBox0"}
                                        checked={this.state.extraActivity[0]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[0]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[0]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[0] = !extraActivity[0]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[0]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox1"}
                                        checked={this.state.extraActivity[1]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[1]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[1]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[1] = !extraActivity[1]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[1]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox2"}
                                        checked={this.state.extraActivity[2]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[2]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[2]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 2);
                                                    }
                                                }
                                                extraActivity[2] = !extraActivity[2]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[2]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox3"}
                                        checked={this.state.extraActivity[3]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[3]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[3]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 3);
                                                    }
                                                }
                                                extraActivity[3] = !extraActivity[3]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[3]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox4"}
                                        checked={this.state.extraActivity[4]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[4]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[4]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 4);
                                                    }
                                                }
                                                extraActivity[4] = !extraActivity[4]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[4]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox5"}
                                        checked={this.state.extraActivity[5]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[5]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[5]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 5);
                                                    }
                                                }
                                                extraActivity[5] = !extraActivity[5]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[5]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox6"}
                                        checked={this.state.extraActivity[6]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[6]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[6]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 6);
                                                    }
                                                }
                                                extraActivity[6] = !extraActivity[6]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[6]}
                                    />

<FormCheckBox
                                        checked={this.state.extraActivity[20]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                let otherActivityStyle: any = this.state.otherActivityStyle;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[1], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push('Other (tell us if you have any requests or ideas)');

                                                    otherActivityStyle = {
                                                        marginLeft: 30,
                                                        width: "calc(100% - 30px)"
                                                    }

                                                } else {
                                                    let index = extraActivitys.indexOf('Other (tell us if you have any requests or ideas)');
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                        otherActivityStyle = {
                                                            display: 'none'
                                                        }
                                                    }
                                                }
                                                extraActivity[20] = !extraActivity[20]
                                                this.setState({ extraActivity, otherActivityStyle })
                                            }
                                        }
                                        label="Other - tell us if you have any requests or ideas"
                                    />
                                    <Form.Control
                                        style={this.state.otherActivityStyle}
                                        type="text"
                                        placeholder="Please type your requests"
                                        onChange={(evt: any) => {
                                            let otherActivityText = this.state.otherActivityText;
                                            otherActivityText = evt.target.value;
                                            this.setState({ otherActivityText });
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>

                                <Form.Group>
                                    <FormCheckBox
                                        key={"FormCheckBox7"}
                                        checked={this.state.extraActivity[7]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[7]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[7]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 7);
                                                    }
                                                }
                                                extraActivity[7] = !extraActivity[7]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[7]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox8"}
                                        checked={this.state.extraActivity[8]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[8]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[8]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 8);
                                                    }
                                                }
                                                extraActivity[8] = !extraActivity[8]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[8]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox9"}
                                        checked={this.state.extraActivity[9]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[9], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[9]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[9]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[9] = !extraActivity[9]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[9]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox10"}
                                        checked={this.state.extraActivity[10]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[10], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[10]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[10]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[10] = !extraActivity[10]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[10]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox11"}
                                        checked={this.state.extraActivity[11]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[11], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[11]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[11]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[11] = !extraActivity[11]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[11]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox12"}
                                        checked={this.state.extraActivity[12]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[12], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[12]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[12]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[12] = !extraActivity[12]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[12]}
                                    />
                                    <FormCheckBox
                                        key={"FormCheckBox13"}
                                        checked={this.state.extraActivity[13]}
                                        ClickHandler={
                                            (checked: boolean) => {
                                                let extraActivity = this.state.extraActivity;
                                                let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                                // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[13], ' - ', this.lop++)
                                                if (checked) {
                                                    extraActivitys.push(extraTours[13]);
                                                } else {
                                                    let index = extraActivitys.indexOf(extraTours[13]);
                                                    if (index > -1) {
                                                        extraActivitys.splice(index, 1);
                                                    }
                                                }
                                                extraActivity[13] = !extraActivity[13]
                                                this.setState({ extraActivity })
                                            }
                                        }
                                        label={extraTours[13]}
                                    />
                                    
                                </Form.Group>
                            </Col>
                            <Col>
                                <FormCheckBox
                                    key={"FormCheckBox14"}
                                    checked={this.state.extraActivity[14]}
                                    ClickHandler={
                                        (checked: boolean) => {
                                            let extraActivity = this.state.extraActivity;
                                            let extraActivitys: string[] = this.state.formData.extraActivitys!;
                                            // console.log("Cooking class with Hotel Komune Chef diclick", extraActivity[0], ' - ', this.lop++)
                                            if (checked) {
                                                extraActivitys.push(extraTours[14]);
                                            } else {
                                                let index = extraActivitys.indexOf(extraTours[14]);
                                                if (index > -1) {
                                                    extraActivitys.splice(index, 14);
                                                }
                                            }
                                            extraActivity[14] = !extraActivity[14]
                                            this.setState({ extraActivity })
                                        }
                                    }
                                    label={extraTours[14]}
                                />
                            </Col>
                            <Col md={12} style={{ textAlign: "center" }}>
                                <Button type="submit">Submit form</Button>
                            </Col>

                        </Row>
                    </Form>

                </Container >
            </section >

        )

    }
}
