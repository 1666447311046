import React from 'react';
import { Container, Card, Button, Carousel, Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import { General } from '../../shared/general';
export const aboutBali: any[] = [
    {
        imageUrl: 'https://storage.googleapis.com/bali-hotel-images/tanah-lot-bali.jpg',
        content: 'See why this beautiful destination is known as “The Island of the Gods”',
        title: 'Explore Bali',
        btnText: 'View Gallery'
    },
    {
        imageUrl: 'https://storage.googleapis.com/bali-hotel-images/bali-activities.jpg',
        content: 'We have over 100 tours & activities, from culture to water sports and theme parks.',
        title: 'Bali Activities',
        btnText: 'View Gallery'
    },
    {
        imageUrl: 'https://storage.googleapis.com/bali-hotel-images/bali-villas.jpg',
        content: 'Find great deals on nearly 2000 hotels in all the best spots on the island.',
        title: 'Bali Hotels',
        btnText: 'View Gallery'
    },
    {
        imageUrl: 'https://storage.googleapis.com/bali-hotel-images/bali-hotels.jpg',
        content: 'Search our site with over 800 private villas for your perfect Bali getaway.',
        title: 'Bali Villas',
        btnText: 'View Gallery'
    },
    {
        imageUrl: 'https://storage.googleapis.com/bali-hotel-images/bali-vip.jpg',
        content: 'Exclusive deals and discounts at Bali’s best beach clubs, restaurants and venues.',
        title: 'Bali VIP',
        btnText: 'View Gallery'
    },
]
export const imagesContent: { [type: string]: General.ImageData[] } = {
    'Explore Bali': [
        {
            title: 'Tanah Lot Bali',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/explore-bali/tanah-lot-bali.jpg-1.jpg'
        },
        {
            title: 'Besakih Tample',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/explore-bali/besakih.jpg'
        }, {
            title: 'Bratan Lake - Bedugul',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/explore-bali/danau-bratan.jpg'
        }, {
            title: 'Kertagosa Klungkung, Bali',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/explore-bali/kerta-gosa-klungkung-bali.jpg'
        }, {
            title: 'Penglipuran Villages',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/explore-bali/penglipuran.jpg'
        },

    ], 'Bali Activities': [
        {
            title: 'Ayung Water Rafting',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-activity/ayung-water-refting.jpg'
        }, {
            title: 'Mount Batur Trekking',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-activity/mount-batur-tracking.jpg'
        }, {
            title: 'Shopping and Market tour Ubud',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-activity/shopping-and-market-tour-ubud-bali.jpg'
        }, {
            title: 'Snorkeling Lembongan Island',
            description: "",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-activity/snorkeling-di-pulau-menjangan.jpg'
        },
        {
            title: "Jungle ATV's",
            description: "Rural Area Of Central Bali Adventure Tour",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-tours/ATV-Bali-With-Bali-Surya-Adventure---Rural-Area-Of-Central-Bali---Adventure-Tour.jpg'
        },{
            title: 'Breakfast With Orangutan ',
            description: "Batubulan Gianyar Cultural Tour",
            altText: '',
            url: 'https://storage.googleapis.com/bali-hotel-images/bali/bali-tours/Breakfast-With-Orang-Utan---Batubulan---Gianyar---Cultural-Tour.jpg'
        }
    ], 'Bali Villas': [
        {
            title: "Villa Anjali Purpl",
            description: "1 Bedroom Villa Seminyak ",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Anjali-Purple---Seminyak---1-Bedroom.jpg"
        },{
            title: "Villa Daria",
            description: "3 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Daria---Seminyak---3-Bedroom.jpg"
        },{
            title: "Villa Du Bah",
            description: "2 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Du-Bah---Seminyak---2-Bedroom.jpg"
        },{
            title: " Villa Dayah",
            description: "2 Bedroom Villa Seminyak",
            altText: "2 Bedroom Villa Seminyak",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Dayah---Seminyak---2-Bedroom.jpg"
        },{
            title: "Villa Du Ho",
            description: "2 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Du-Ho---Seminyak---2-Bedroom.jpg"
        },{
            title: "Villa Lili",
            description: "3 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Lili---Seminyak---3-Bedroom.jpg"
        },{
            title: "Villa Lotus",
            description: "4 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Lotus---Seminyak---4-Bedroom.jpg"
        },{
            title: "Villa Portsea",
            description: "2 Bedroom Villa Seminyak",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-villas/Villa-Portsea---Seminyak---2-Bedroom.jpg"
        }
    ], 'Bali Hotels': [
        {
            title: "Bali Dynasty Resort",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/Bali-Dynasty-Resort---Kuta.jpg"
        },{
            title: "Double Six Resort",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/Double-Six-Resort-Bali---Seminyak.jpg"
        },{
            title: "Four Points ",
            description: " Sheraton Bali",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/Four-Points-by-Sheraton-Bali---Seminyak.jpg"
        },{
            title: "Hotel Pelangi Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/Hotel-Pelangi-Bali-%26-Spa---Seminyak.jpg"
        },{
            title: "Legian Beach Hotel",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/Legian-Beach-Hotel---Legian.jpg"
        },{
            title: "The Stones Legian",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/The-Stones-Legian---Legian.jpg"
        },{
            title: "The Tjampuhan & Spa",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-hotels/The-Tjampuhan-%26-Spa---Ubud.jpg"
        },
    ], 'Bali VIP': [
        {
            title: "Bronz Spa Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Bronz-Spa-Bali---Seminyak---Spa-In-Bali.jpg"
        },{
            title: "Karma Beach",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Karma-Beach---Jimbaran---Beach-Club.jpg"
        },{
            title: "Ku De Ta",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Ku-De-Ta---Seminyak---Beach-Club.jpg"
        },{
            title: "Motel Mexicola",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Motel-Mexicola---Petitenget-Kerobokan---Restaurant.jpg"
        },{
            title: "Omnia Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Omnia-Bali---Uluwatu---Day-Club.jpg"
        },{
            title: "Potato Head Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Potato-Head-Bali---Kerobokan---Beach-Club.jpg"
        },{
            title: "Rejuvie Clinic",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Rejuvie-Clinic---Legian---Cosmetic-Surgery.jpg"
        },{
            title: "Soul Rebel Tatto Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Soul-Rebel-Tatto-Bali---Kerobokan-Klod---Tatto-Studio.jpg"
        },{
            title: "Sundays Beach Club",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Sundays-Beach-Club---Uluwatu---Beach-Club.jpg"
        },{
            title: "Water Bom Bali",
            description: "",
            altText: "",
            url: "https://storage.googleapis.com/bali-hotel-images/bali/bali-vip/Water-Bom-Bali---Kuta---Meeting-Group.jpg"
        }
    ],

}

export interface AboutBaliProps { }
export interface AboutBaliState {
    modalImageshow: { [type: string]: boolean };
}
export class AboutBali extends React.Component<AboutBaliProps, AboutBaliState>  {

    constructor(props: AboutBaliProps) {
        super(props);
        this.state = {
            modalImageshow: {
                'Explore Bali': false,
                'Bali Activities': false,
                'Bali Villas': false,
                'Bali Hotels': false,
                'Bali Vip': false
            }
        }
    }

    renderCarousel = (title: string) => {
        let isiCarousel: any[] = [];
        for (let a of imagesContent[title]) {
            isiCarousel.push(
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={a.url}
                        alt={a.url}
                        style={{ borderRadius: '0 0 15px 15px', opacity: .6 }}
                    />
                    <Carousel.Caption>
                        <h3>{a.title}</h3>
                        <p>{a.description}</p>
                    </Carousel.Caption>
                </Carousel.Item>
            )
        }
        return (
            <Carousel>
                {isiCarousel}
            </Carousel>
        )
    }

    render() {
        let slider: any[] = [];
        const handleClose = (type: string) => {

            this.setState({
                modalImageshow: {
                    [type]: false
                }
            })
        };
        const handleShow = (type: string) => {
            this.setState({
                modalImageshow: {
                    [type]: true
                }
            })
        };;
        for (let data of aboutBali) {
            slider.push(
                <div style={{ padding: '0 5px' }}>
                    <Card style={{ width: '100%', textAlign: 'center' }}>
                        <Card.Img variant="top" src={data.imageUrl} />
                        <Card.Body>
                            <Card.Title>{data.title}</Card.Title>
                            <Card.Text>{data.content}</Card.Text>
                            <Button variant="primary" onClick={() => { handleShow(data.title) }}>{data.btnText}</Button>
                        </Card.Body>
                    </Card>
                    <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={this.state.modalImageshow[data.title]} onHide={() => { handleClose(data.title) }}>
                        <Modal.Header closeButton> 
                                {data.title} 
                        </Modal.Header>
                        <Modal.Body style={{ background: "#000", padding: 0 }}>
                            {this.renderCarousel(data.title)}
                        </Modal.Body>
                    </Modal>
                </div>

            )
        }
        let settings: any = {
            dots: false,
            infinite: true,
            speed: 3000,
            slidesToShow: 4,
            slidesToScroll: 4,
            autoplay: true,
            centerPadding: 20, 
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                },
            ],
            marginTop: 30
        };
        return (
            <section>
                <style>
                    {
                        `
                        .slick-track {
                            margin-top: 30px;
                        }
                        `
                    }
                </style>
                <Container style={{ textAlign: "center" }}>
                    <h3 style={{ marginBottom: 30 }}>About Bali</h3>
                   
                    <Slider   {...settings}>
                        {slider}
                    </Slider>
                </Container>
            </section>

        )

    }
}