import React from 'react';
import { Container } from 'react-bootstrap';
import Slider from 'react-slick';

const Press: any[] = [
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/abc-radio-australia.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/abc.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/ask.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/australian-business-review.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/big-spring-herald.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/bing-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/cbs-nes.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/cnn.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-herald.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-media-press-group.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/daily-times.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/fox-nes.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/google-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/karn-news-radio.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/marketplaces.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/nbc.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/pilot-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/sbs.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/startribun.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/street-insider.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-evening.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-morning-news.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/the-post-and-mail.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/travel-industry-today.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/tripadvisor.jpg", url: "https://www.tripadvisor.com/Attraction_Review-g303952-d13993230-Reviews-Bali_Tours_And_More-Kintamani_Bangli_Bali.html" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/wall-street-select.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/warl.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/wnd.jpg", url: "" },
    { imgPath: "https://storage.googleapis.com/bali-hotel-images/press/yahoo.jpg", url: "" },
];
 

export interface InThePressProps { }

export function InThePress(Props: InThePressProps) {
    let inthepress: any[] = []; 
    let settings: any = {
        dots: false,
        infinite: true,
        speed: 3000,
        slidesToShow: 6,
        slidesToScroll: 3,
        autoplay: true,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
        ]
    };
    let loop = 0;
    for (let t of Press) {
        loop++;
        let key = `inthePress${loop}`;
        if (t.url !== "") {
            inthepress.push(
                <a key={key} href={t.url} target="_blank" rel="noopener noreferrer">
                    <img src={t.imgPath} width="100%" alt="" />
                </a>
            );
        } else {
            inthepress.push(<img key={key} src={t.imgPath} width="100%" alt="" />);
        }
    }

    return (
        <section style={{ margin: '30px 0 30px' }}>

            <Container style={{ textAlign: "center" }}>
                <h3>In The Press</h3>
                <hr />
                <Slider {...settings}>
                    {inthepress}
                </Slider>
            </Container>
        </section>

    )

}



